import './styles/globals.css';
import Page from './page'

const RootLayout = ({ children }) => (
  <>
    <Page />    
  </>
);

export default RootLayout;
