import React from 'react'
import RootLayout from './layout'
import './styles/globals.css';

const App = () => {
  return (
    <div>
        <RootLayout />
    </div>
  )
}

export default App