import { motion } from "framer-motion";
import styles from "../styles";
import { fadeIn } from "../utils/motion";
import React from "react";

const ExploreCard = ({
  id,
  imgUrl,
  title,
  desc,
  visitUrl,
  index,
  active,
  handleClick,
}) => (
  <motion.div
    variants={fadeIn("right", "spring", index * 0.5, 0.75)}
    className={`relative ${
      active === id ? "lg:flex-[3.5] flex-[10]" : "lg:flex-[0.5] flex-[2]"
    } flex items-center justify-center min-w-[170px] h-[700px] transition-[flex] duration-[0.7s] ease-out-flex cursor-pointer`}
    onClick={() => handleClick(id)}
  >
    <img
      src={`/public/${imgUrl}`}
      alt={title}
      className="absolute w-full h-full object-cover rounded-[24px]"
    />
    {active !== id ? (
      <h3 className=" font-semibold sm:text-[26px] text-[18px] text-white absolute z-0 lg:bottom-20 lg:rotate-[-90deg] jg:origin-[0,0]">
        {title}
      </h3>
    ) : (
      <div className="absolute bottom-0 p-8 justify-start w-full flex-col bg-[rgba(0,0,0,0.5)] rounded-b-[24px]">
        <div
          className={`${styles.flexCenter} w-[60px] h-[60px] rounded-[24px] glassmorphism mb-[16px] hover:bg-transparent`}
        >
          <a href={visitUrl} className="w-1/2 h-1/2 object-contain">
            <img
              src="/public/headset.svg"
              alt="headset"
              className="w-full h-full object-contain"
            />
          </a>          
        </div>
        <p className="font-normal uppercase text-[16px] leading-[20px] text-white">
          {title}
        </p>
        <h2 className="mt-[24px] text-white font-semibold sm:text-[32px] text-[24px]">
          {desc}
        </h2>
      </div>
    )}
  </motion.div>
);

export default ExploreCard;
