// "use client";
import React, { useState, Fragment } from "react";
import { motion } from "framer-motion";
import { socials } from "../constants";
import styles from "../styles";
import { footerVariants } from "../utils/motion";
import { Dialog, Transition } from "@headlessui/react";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const initState = {
    name: "",
    email: "",
    message: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [sending, setSending] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleInput = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setSending(true);
    // console.log(process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
    emailjs
      .send(
        "service_pb2j5z7",
        "template_baxm9av",
        {
          to_name: "info@enauro.com",
          from_name: formDetails.name,
          message: formDetails.message,
          from_email: formDetails.email,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          // console.log(result.text);
          if (result?.text === "OK") {
            alert(
              "Message was successfully sent. We will come back to you soon!"
            );
            setFormDetails(initState);
            closeModal();
          }
          setSending(false);
        },
        (error) => {
          // console.log(error.text);
          setEmailError(true);
          setSending(false);
        }
      );
  };

  return (
    <>
      <motion.footer
        variants={footerVariants}
        initial="hidden"
        whileInView="show"
        className={`${styles.xPaddings} py-8 relative`}
      >
        <div className="footer-gradient" />
        <div className={`${styles.innerWidth} mx-auto flex flex-col gap-8`}>
          <div className="flex items-center justify-between flex-wrap gap-5">
            <h4 className="font-bold md:text-[64px] text-[44px] text-white">
              Let's find out more
            </h4>
            <button
              type="button"
              className="flex items-center h-fit py-4 px-6 bg-[#25618B] rounded-[32px] gap-[12px] hover:bg-transparent"
              onClick={() => openModal()}
            >
              <img
                src="/public/headset.svg"
                alt="headset"
                className="w-[24px] h-[24px] object-contain"
              />
              <span className="font-normal text-[16px] text-white">
                Contact Us
              </span>
            </button>
          </div>

          {/* Modal */}

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-[#132e3e] p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="mt-[10px] font-bold text-[24px] leading-[30px] text-white"
                      >
                        Contact Form
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="flex-1 mt-[16px] font-normal text-[18px] text-[#b0b0b0] leading-[32px]">
                          Fill out this contact form and we will contact you as
                          soon as possible.
                        </p>
                      </div>
                      {/* <div className="mt-2 w-full p-5 border-solid border-gray-400 border-[1px] rounded-xl"> */}
                      <div className="mt-2 w-full p-5">
                        <div className="flex flex-col justify-center items-center w-full gap-3">
                          <input
                            type="text"
                            placeholder="Name"
                            className="p-2 text-white w-full rounded-lg bg-inherit hover:bg-slate-300 border-[1px] border-solid border-gray-400"
                            onChange={handleInput}
                            name="name"
                          />
                          <input
                            type="email"
                            required={true}
                            placeholder="example@example.com"
                            className="p-2 text-white w-full rounded-lg bg-inherit hover:bg-slate-300 border-[1px] border-solid border-gray-400"
                            onChange={handleInput}
                            name="email"
                          />
                          <textarea
                            type="text"
                            placeholder="Your message here"
                            className="p-2 text-white font-extralight w-full rounded-lg bg-inherit hover:bg-slate-300 border-[1px] border-solid border-gray-400"
                            onChange={handleInput}
                            name="message"
                            rows={5}
                          />
                        </div>
                      </div>

                      <div className="mt-4 flex flex-row justify-right items-center gap-2">
                        <button
                          type="button"
                          className={`inline-flex justify-center rounded-md border border-transparent ${sending ? "bg-transparent" : "bg-slate-500"} px-4 py-2 text-sm font-medium text-white hover:bg-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                          onClick={handleSubmit}
                          disabled={sending}
                        >
                          {sending ? "Sending..." : "Submit"}
                        </button>
                        <button
                          type="button"
                          className={`inline-flex justify-center rounded-md border border-transparent ${sending ? "bg-transparent" : "bg-slate-500"} px-4 py-2 text-sm font-medium text-white hover:bg-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        {emailError && (
                          <p className="text-red-500">
                            Error sending the message. Please retry again
                          </p>
                        )}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>

          {/* End of Modal */}

          <div className="flex flex-col">
            <div className="mb-[50px] h-[2px] bg-white opacity-10" />

            <div className="flex items-center justify-between flex-wrap gap-4">
              <h4 className="font-extrabold text-[24px] text-white">
                ENAURO DIGITAL
              </h4>
              <p className="font-normal text-[14px] text-white opacity-50">
                Copyright © 2021 - 2022 Enauro Web Services. All rights
                reserved.
              </p>

              <div className="flex gap-4">
                {socials.map((social) => (
                  <img
                    key={social.name}
                    src={`/public${social.url}`}
                    alt={social.name}
                    className="w-[24px] h-[24px] object-contain cursor-pointer"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.footer>
    </>
  );
};

export default Footer;
