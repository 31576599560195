export const exploreWorlds = [
  {
    id: 'world-1',
    imgUrl: '/project001.png',
    title: 'Enauro Store',
    desc: 'HiFi & Tech E-Commerce',
    visitUrl:'https://ecommerce-kappa-five.vercel.app/'
  },
  {
    id: 'world-2',
    imgUrl: '/project002.png',
    title: 'Apple Redesign',
    desc: 'Apple-style E-Commerce',
    visitUrl:''
  },
  {
    id: 'world-3',
    imgUrl: '/project003.png',
    title: 'Car Hub',
    desc: 'Rental Car Portal',
    visitUrl: 'https://next-car-showcase-pado.vercel.app/'
  },
  {
    id: 'world-4',
    imgUrl: '/project004.png',
    title: 'Dashboard',
    desc: 'Performance Portal',
    visitUrl: 'https://dashboard-five-delta.vercel.app/'
  },  
  {
    id: 'world-5',
    imgUrl: '/project005.png',
    title: 'Web3',
    desc: 'Crowdfounding Demo App',
    visitUrl: 'https://crowdfunding-self.vercel.app/'
  },
];

export const startingFeatures = [
  'Proven experience in development and implementation of automation and MES projects.',
  'Use of market-leading technologies. Model-Driven MES approach',
  'Complete vertical plant integration. From the control layer to corporate resources.',
];

export const newFeatures = [
  {
    imgUrl: '/vrpano.svg',
    title: 'Frontend',
    subtitle:
        'Development of stunning applications using the most popular technologies and frameworks on the market, such as React or Nextjs.',
  },
  {
    imgUrl: '/headset.svg',
    title: 'Backend',
    subtitle:
        'For the development of backend solutions we use the most widely used technologies, such as .NET or Nodejs.',
  },
  {
    imgUrl: '/headset.svg',
    title: 'Databases',
    subtitle:
    'We rely on a wide variety of databases in our applications, depending on the needs of the project, both relational (SQL Server) and non-relational (MongoDB).',
  },
  {
    imgUrl: '/headset.svg',
    title: 'Deployment',
    subtitle:
        'We adapt to the needs of our clients for the deployment of applications, both on premises and in the cloud. We offer deployment in services such as Azure or AWS.',
  },
];

export const insights = [
  {
    imgUrl: '/planet-06.png',
    title: 'The launch of the Metaverse makes Elon musk ketar-ketir',
    subtitle:
        'Magna etiam tempor orci eu lobortis elementum nibh tellus molestie. Diam maecenas sed enim ut sem viverra alique.',
  },
  {
    imgUrl: '/planet-07.png',
    title: '7 tips to easily master the madness of the Metaverse',
    subtitle:
        'Vitae congue eu consequat ac felis donec. Et magnis dis parturient montes nascetur ridiculus mus. Convallis tellus id interdum',
  },
  {
    imgUrl: '/planet-08.png',
    title: 'With one platform you can explore the whole world virtually',
    subtitle:
        'Quam quisque id diam vel quam elementum. Viverra nam libero justo laoreet sit amet cursus sit. Mauris in aliquam sem',
  },
];

export const socials = [
  {
    name: 'twitter',
    url: '/twitter.svg',
  },
  {
    name: 'linkedin',
    url: '/linkedin.svg',
  },
  {
    name: 'instagram',
    url: '/instagram.svg',
  },
  {
    name: 'facebook',
    url: '/facebook.svg',
  },
];
