import styles from '../styles'
import React from 'react'

const StartSteps = ({number, text}) => (
  <div className={`${styles.flexCenter} flex-row`}>
    <div className={`${styles.flexCenter} w-[70px] h-[70px] rounded-[24px] bg-[#323f5d]`}>
      <p className='font-bold text-[20px] text-white'>{number}</p>
    </div>
    <p className='flex-1 ml-[30px] font-normal atext-[18px] leading-[32px] text-[#B0B0B0]'>{text}</p>
  </div>
);

export default StartSteps;
