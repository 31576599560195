
import React from 'react'
import {motion} from 'framer-motion'
import styles from '../styles'
import {navVariants} from '../utils/motion'

const Navbar = () => (
  <motion.nav
    variants={navVariants}
    initial="hidden"
    whileInView="show"
    className={`${styles.xPaddings} py-8 relative`}
  
  >
   <div className='absolute w-[50%] inset-0 gradient-01' />
   <div className={`${styles.innerWidth} mx-auto flex justify-between gap-8`}>
    <div className='flex flex-row gap-2'>
      <h2 className='font-extrabold text-[24px] leading-[30px] text-white'>ENAURO</h2>
      <h2 className='font-extralight text-[24px] leading-[30px] text-white'>DIGITAL</h2>
    </div>
    <div className='flex flex-1 flex-row justify-end items-center px-5 gap-10'>
      <a href="#about" className='font-light text-[14px] text-[rgb(199,199,199)]'>About</a>      
      <a href="#projects" className='font-light text-[14px] text-[rgb(199,199,199)]'>Projects</a>      
      <a href="#automationMES" className='font-light text-[14px] text-[rgb(199,199,199)]'>Automation MES</a>      
      <a href="#digitalSolutions" className='font-light text-[14px] text-[rgb(199,199,199)]'>Digital Solutions</a>      

    </div>
    <div className='flex flex-row gap-5'>
      <img src="/public/search.svg" alt="search"className='w-[24px] b-[24px] object-contain'/>    
      <img src="/public/menu.svg" alt="menu" className='w-[24px] h-[24px] object-contain'/>
    </div>
    
    
   </div>
  </motion.nav>
);

export default Navbar;
