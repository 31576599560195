"use client";
import { motion } from "framer-motion";
import { TypingText } from "../components";
import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";

const About = () => (
  <section className={`${styles.paddings} relative z-10`} id="about">
    <div className="gradient-02 z-0" />
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto ${styles.flexCenter} flex-col`}
    >
      <TypingText title="| About Enauro Web Services" textStyles="text-center" />
      <motion.p
      variants={fadeIn('up', 'teen', 0.2,1)}
      className="mt-[8px] font-light sm:text=[32px] text-[20px] text-center text-white"
      >
        At <span className="font-extrabold text-white">Enauro Digital </span> 
        we are convinced that the digital future is becoming more and more present, so our goal is to develop <span className="font-extrabold text-white">competitive solutions  </span>that allow our customers to improve their operations and thus become their main <span className="font-extrabold text-white">technology partner.</span> Let's<span className="font-extrabold text-white"> explore</span> the projects and services we can offer you by scrolling down
      </motion.p>
      <motion.img
      variants={fadeIn('up', 'teen', 0.3,1)}
      src="/public/arrow-down.svg"
      alt="arrow down"
      className="w-[18px] h-[280x] object-contain mt-[28px]" />      
    </motion.div>
  </section>
);

export default About;
